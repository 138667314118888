@import url('https://rsms.me/inter/inter.css');

@font-face {
    font-family: 'NeuzeitSLTStd-BookHeavy';
    src: url('../public/fonts/NeuzeitSLTStd-BookHeavy.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

a {
    pointer-events: all;
    color: black;
    text-decoration: none;
}
  
ul {
    list-style: none;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
  overscroll-behavior: none;
  background:rgba(255,255,255, 1);
  /* font-family: 'Inter var', sans-serif; */
  font-family: Arial, Helvetica, sans-serif;

  /* cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxMCIgZmlsbD0iYmxhY2siLz48L3N2Zz4='),
    auto; */
}

/* Navigation */

.bar{
    height: 2px;
    width: 27px;
    background: rgba(0, 0, 0, 0.75);
    margin: 5px 0;
    opacity: 0.8;
    transition: all 0.3s ease-in-out; /* Smooth transition */
}

.logo {
    color: rgb(0, 0, 0);
    font-size: 23px;
    letter-spacing: -1.0px;
    font-weight: 500;
    transition: font-size 0.1s ease-in-out; /* Add transition for smooth font size and color change */
    /* font-family: Arial, Helvetica, sans-serif; */
    /* font-family: 'NeuzeitSLTStd-BookHeavy'; */
}

.logo:hover
 {
    /* font-size: 24px; */
    color: rgb(150, 150, 150);
}

.bar--hidden {
    opacity: 0; /* You can also use display: none if needed */
}

.bar--rotate1 {
    transform: translateY(7px) rotate(45deg);
}

.bar--rotate2 {
    transform: translateY(-7px) rotate(-45deg);
}

.header {
    margin: 0;
    padding: 0;
    box-sizing: flex;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    backdrop-filter: blur(10px); 
    background-color: rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    z-index: 100;
}

.header__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    min-height: 60px;
}


.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 80px;
    backdrop-filter: blur(10px); 
    background-color: rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    z-index: 0;
}

.nav__list {
    display: flex;
    column-gap: 40px;
}

.nav__link {
    color: rgb(0, 0, 0);
    font-size: 20px;
    transition: all 0.1s ease-in-out;
}

.nav__link:hover,
.nav__link:focus
 {
    color: rgb(150, 150, 150);
}

.nav--open {
    transform: translate(0) !important;
}

.hamburger {
    display: none;
    pointer-events: all; /* Allow pointer events */
}

.hamburger:hover {
    cursor: pointer;
}

/* Projects */

.projects__link {
    color: rgb(0, 0, 0);
    transition: color 0.2s ease-in-out;
}

.projects__link:hover,
.projects__link:focus
{
    color: rgb(150, 150, 150);
}

/* Blog */

.row {
    margin: 0;
    padding: 0;
    width: 100%;
}

.rightcolumn {
    float: left;
    width: 25%;
    padding-left: 20px;
}

.fakeimg {
    background-color: #aaa;
    width: 100%;
    padding: 20px;
}

.card {
    background-color: rgba(233, 233, 233, 0.605);
    padding: 20px;
    margin-top: 20px;
}
  
.row:after {
    content: "";
    display: table;
    clear: both;
}

.anchor {
    color: rgb(0, 0, 0);
    font-size: 20px;
    transition: all 0.1s ease-in-out;
}

.anchor:hover,
.anchor:focus
 {
    color: rgb(150, 150, 150);
}

  
@media (max-width: 650px) {
    .nav{
        position: fixed;
        top: 64px;
        left: 0;
        backdrop-filter: blur(10px); /* Glass effect with blur */
        background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white background */
        width: 100%;
        padding: 10px 0 25px;
        transform: translateX(-100%);
        /* transition: transform 0.3s ease-in-out;  */
        border-bottom: 1px solid rgba(255, 255, 255, 0.18); /* Optional border */
    }

    .nav__list{
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }

    .nav__link{
        font-size: 14px;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }
}
